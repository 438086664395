import gql from "graphql-tag";

export const GET_AVERAGE_TIME_BY_APPOINTMENT_AND_STAFF_AND_CENTER_DETAILS = gql`
  query getAverageTimeByAppointmentAndStaffAndCenterDetails(
    $where: WhereAverageAndTicket
  ) {
    getAverageTimeByAppointmentAndStaffAndCenterDetails(where: $where) {
      patient
      date
      start
      end
      time
      optimum
    }
  }
`;
