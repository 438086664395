import React from "react";

const optimumValue = (item, type) => {
  let optimumLevel = item.optimumLevel;

  switch (item.typeId) {
    case 1:
      return type === 0 && (item.optimumLevel ? "Positivo" : "Negativo");
    case 2:
      return type === 0 && (item.optimumLevel ? "Si" : "No");
    case 3:
      return optimumLevel && optimumLevel.split("<>")[type];
    case 4:
      console.log(optimumLevel.split("<>"));
      return optimumLevel && optimumLevel.split("<>")[type];
    default:
      break;
  }
};

export const CustomOptimum = (item, type) => {
  return <div>{optimumValue(item, type)}</div>;
};
