import React, { Suspense } from "react";
import * as router from "react-router-dom";

import routes from "../../routes";
import { Container } from "reactstrap";
import { useAppContext } from "../../AppContext";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@meds/contexts/react-auth0-spa";

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav
} from "@coreui/react";

const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

const loading = () => (
  <div className="animated fadeIn pt-1 text-center">{"Loading..."}</div>
);

const arrRoutesExpluded = [
  "/dashboard/inventory/type-pruduct-center/id/typeID/product-type-center/details-transactions-center/:CenterId/:typeId/:pID/:invType",
  "/dashboard/inventory/details-type-product/id/details-transactions-product/:id/:typeId/:transID",
  "/dashboard/inventory/type-pruduct-center/id/typeID/product-type-center/:id/:typeID/:invType",
  "/dashboard/inventory/type-pruduct-center/:id/:typeID",
  "/dashboard/inventory/details-type-product/:id/:typeID",
  "/dashboard/inventory/details-provider/:id/:typeID",
  "/dashboard/inventory"
];

export default function DefaultLayout(props) {
  const auth0 = useAuth0();
  const { state: ctx, dispatch } = useAppContext();
  function signOut(e) {
    e.preventDefault();
    dispatch({ type: "LOGOUT" });
    auth0.logout({ returnTo: window.location.origin });
  }

  const newRoutes = routes.filter(
    route =>
      route.path !==
      arrRoutesExpluded.find(excludedRoute => route.path === excludedRoute)
  );

  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader onLogout={signOut} />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed minimized display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav
              {...props}
              router={router}
              navConfig={ctx.navigation}
            />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={newRoutes} router={router} />
          <Container fluid>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      <AppFooter>
        <Suspense fallback={loading()}>
          <DefaultFooter />
        </Suspense>
      </AppFooter>
    </div>
  );
}
