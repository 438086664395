import React, { useState } from "react";
import { map } from "lodash";

import {
  FormGroup,
  Label,
  Row,
  Form,
  TabPane,
  Input,
  Button,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactTable from "react-table-v6";
import useActions from "../use-actions";
import ReactSelect from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import InterpretationModal from "./InterpretationModal";
import NumberFormat from "react-number-format";
import StatusButton from "../../../../../components/molecules/status-button";
import { TOGGLE_ENABLED_ANALYSIS_METHOD_PRODUCT } from "../queries";

function GeneralTab() {
  const { id } = useParams();
  const isUpdating = id ? true : false;
  const { register, handleSubmit, errors } = useForm();
  const [showModal, setShowModal] = useState(false);
  const {
    state,
    actions: {
      setValue,
      onCreateAnalysisMethod,
      onUpdateAnalysisMethod,
      addNewProduct,
      onUpdateAnalysisMethodProduct,
      onFillInputsWithSelectedAMP,
      validateproduct,
      validateGeneroSelected,
      validateMaxMin,
      validateEndAge,
      validateStartAge,
      validateUnitMeasurement,
      validateTypeSelected,
      onRefetchAnalysisMethodProducts
    }
  } = useActions({ id });

  let exclude = state.productsCache.map(item => `${item.productId}`);

  const toggleModal = () => setShowModal(!showModal);
  const labelTipoSelected =
    state.typeSelected !== -1 &&
    state.analysisMethodTypes.length > 0 &&
    state.analysisMethodTypes.find(amt => amt.id === state.typeSelected).name;

  const columnsProductCache = [
    {
      Header: "Exámen",
      accessor: "name",
      Cell: ({ original }) => {
        const product = state.products.find(
          p => Number(p.id) === original.productId
        );
        return <span>{product?.name || original?.name}</span>;
      }
    },
    {
      Header: "Tipo",
      accessor: "type",
      Cell: ({ original }) => {
        const type = state.analysisMethodTypes.find(
          amt => amt.id === original.typeId
        );
        return <span>{type.name}</span>;
      }
    },
    {
      Header: "Unidad de Medida",
      accessor: "unitMeasurement"
    },
    {
      Header: "Sexo",
      Cell: ({ original }) => {
        const gender = state.genders.find(
          g => /* Number( */ g.id /* ) */ === original.genderId.toString()
        );
        return <span>{gender?.name}</span>;
      }
    },
    {
      Header: "Mínimo",
      Cell: ({ original }) => {
        return (
          <span
            style={{
              float:
                original.type === "Valor Absoluto (9999.99)" ||
                original.type === "Valor Porcentual (%)"
                  ? "right"
                  : "left"
            }}
          >
            {" "}
            {original.minimum}
          </span>
        );
      }
    },
    {
      Header: "Máximo",
      Cell: ({ original }) => {
        return (
          <span
            style={{
              float:
                original.type === "Valor Absoluto (9999.99)" ||
                original.type === "Valor Porcentual (%)"
                  ? "right"
                  : "left"
            }}
          >
            {" "}
            {original.maximum}
          </span>
        );
      }
    },
    {
      Header: "Edad",
      Cell: ({ original }) => {
        return `Desde ${original.startAge} Hasta ${original.endAge}`;
      }
    },
    {
      Header: "Interpretación",
      accessor: "interpretatation",
      Cell: ({ original }) => {
        return (
          <>
            <Button
              className="card-header-action text-center"
              onClick={() => {
                setCurrentInterpretation(original.interpretatation);
                toggleModal();
              }}
              color="warning"
            >
              {" "}
              Ver interpretación
            </Button>
          </>
        );
      }
    },
    {
      Header: "Opciones",
      Cell: ({ original }) => {
        return (
          <>
            <Button
              type="button"
              className="btn-gray-600 card-header-action"
              onClick={() => onFillInputsWithSelectedAMP(original)}
            >
              <i className="fa fa-pencil"></i>
              {"Editar"}
            </Button>
            <StatusButton
              itemId={original.id}
              status={original.enabled}
              mutate={TOGGLE_ENABLED_ANALYSIS_METHOD_PRODUCT}
              onCompleted={onRefetchAnalysisMethodProducts}
            />
          </>
        );
      }
    }
  ];

  const dataProductCache = () => {
    if (state && state.productsCache && state.products && state.genders) {
      return state.productsCache.map(pc => {
        const caculatedData = {
          id: pc.id,
          interpretatation: pc.interpretation,
          productId: pc.productId,
          genderId: pc.genderId,
          minimum: pc.minimum,
          maximum: pc.maximum,
          endAge: pc.endAge,
          startAge: pc.startAge,
          typeId: pc.typeId,
          unitMeasurement: pc.unitMeasurement,
          enabled: pc.enabled,
          name: pc.name
        };

        return caculatedData;
      });
    }
  };

  const onSubmit = async data => {
    const { method } = data;
    setValue({
      value: data.method,
      key: "method"
    });
    if (!state.productsCache.length) {
      toast.error("Debes Ingresar almenos un producto");
      return;
    }
    if (isUpdating) {
      await onUpdateAnalysisMethod({ method });
    } else {
      await onCreateAnalysisMethod({ method });
    }
  };

  const getMarked = ({ forMin }) => {
    const onChangeSelect = e => {
      validateMaxMin(e.value);
      setValue({
        key: forMin ? "min" : "max",
        value: e.value
      });
    };
    const onChange = e => {
      validateMaxMin(e.value);
      setValue({
        key: forMin ? "min" : "max",
        value: e.currentTarget.value
      });
    };

    switch (state.typeSelected) {
      case 1:
        return (
          <ReactSelect
            onChange={onChangeSelect}
            value={{
              label: state[forMin ? "min" : "max"],
              value: state[forMin ? "min" : "max"]
            }}
            options={[
              {
                label: "Positivo",
                value: "Positivo"
              },
              {
                label: "Negativo",
                value: "Negativo"
              }
            ]}
          />
        );
      case 2:
        return (
          <ReactSelect
            onChange={onChangeSelect}
            value={{
              label: state[forMin ? "min" : "max"],
              value: state[forMin ? "min" : "max"]
            }}
            options={[
              {
                label: "Si",
                value: "Si"
              },
              {
                label: "No",
                value: "No"
              }
            ]}
          />
        );
      case 3:
        return (
          <NumberFormat
            format="###.00%"
            min={0}
            max={100}
            className="form-control"
            onChange={onChange}
            value={state[forMin ? "min" : "max"]}
          />
        );
      case 4:
        return (
          <NumberFormat
            decimalScale={2}
            className="form-control"
            onChange={onChange}
            value={state[forMin ? "min" : "max"]}
          />
        );
      case 5:
        return (
          <Input
            type="text"
            value={state[forMin ? "min" : "max"]}
            onChange={onChange}
          />
        );
      case 6:
        return (
          <ReactSelect
            onChange={onChangeSelect}
            value={{
              label: state[forMin ? "min" : "max"],
              value: state[forMin ? "min" : "max"]
            }}
            options={[
              {
                label: "Crecimiento",
                value: "Crecimiento"
              },
              {
                label: "No Crecimiento",
                value: "No Crecimiento"
              }
            ]}
          />
        );
      default:
        return;
    }
  };

  const [currentInterpretation, setCurrentInterpretation] = useState("");

  console.log(state);

  return (
    <TabPane tabId={1} active={1}>
      <InterpretationModal
        text={currentInterpretation}
        toggle={toggleModal}
        showModal={showModal}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label for="methodLabel">Método</Label>
              <Input
                type="text"
                defaultValue={isUpdating ? state.item && state.item.name : ""}
                innerRef={register({ required: true })}
                style={{ backgroundColor: "white" }}
                name="method"
              />
              {errors.method && (
                <span style={{ color: "red" }}>
                  {"El método es requerido "}
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label for="productLabel">Examen de Laboratorio</Label>
              <ReactSelect
                name="productSelect"
                onChange={e => {
                  setValue({
                    key: "selectedProduct",
                    value: Number(e.id)
                  });
                  validateproduct(Number(e.id));
                }}
                value={
                  state.selectedProduct !== -1 && {
                    label: state.products.find(
                      p => parseInt(p.id) === state.selectedProduct
                    )?.name,
                    value: state.products.find(
                      p => parseInt(p.id) === state.selectedProduct
                    )?.name
                  }
                }
                options={
                  state.products &&
                  state.products.map(product => ({
                    label: product.name,
                    value: product.name,
                    id: product.id
                  }))

                  // map(
                  //   state.products.filter(item => {
                  //     return !exclude.includes(`${item.id}`);
                  //   }),
                  //   item => ({
                  //     label: item.name,
                  //     value: item.name,
                  //     id: item.id
                  //   })
                  // )
                }
              />
              {!state.validations.product && !state.validations.initial && (
                <span style={{ color: "red" }}>
                  {"Este campo es requerido"}
                </span>
              )}
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup>
              <Label for="unitMeasurementLabel">Unidad de Medida</Label>
              <Input
                type="text"
                name="unitMeasurement"
                onChange={e => {
                  const value = e.currentTarget.value;
                  setValue({
                    key: "unitMeasurement",
                    value
                  });
                  validateUnitMeasurement(value);
                }}
                value={state.unitMeasurement}
              />
              {!state.validations.unitMeasurement &&
                !state.validations.initial && (
                  <span style={{ color: "red" }}>
                    {"Este campo es requerido"}
                  </span>
                )}
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup>
              <Label for="genderLabel">Sexo</Label>
              <ReactSelect
                name="genderSelect"
                value={
                  state.selectedGenderId !== -1 && {
                    label:
                      state.genders.find(
                        g => parseInt(g.id) === state.selectedGenderId
                      )?.name ||
                      state.genders.find(g => g.id === state.selectedGenderId)
                        ?.name,
                    value:
                      state.genders.find(
                        g => parseInt(g.id) === state.selectedGenderId
                      )?.name ||
                      state.genders.find(g => g.id === state.selectedGenderId)
                        ?.name
                  }
                }
                onChange={e => {
                  setValue({
                    key: "selectedGenderId",
                    value: e.id
                  });
                  validateGeneroSelected(parseInt(e.id));
                }}
                options={
                  state.genders &&
                  state.genders
                    .filter(gender => gender.name !== "No Definido")
                    .map(gender => ({
                      value: gender.name,
                      label: gender.name,
                      id: gender.id
                    }))
                }
              />
              {!state.validations.gender && !state.validations.initial && (
                <span style={{ color: "red" }}>
                  {"Este campo es requerido"}
                </span>
              )}
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label for="unitMeasurementLabel">Edad</Label>
              <div className="d-flex ">
                <InputGroup className="w-50 ">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Desde</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min={0}
                    value={state.startAge === -1 ? "" : state.startAge}
                    className="mr-2"
                    onChange={e => {
                      const value = parseInt(e.currentTarget.value);
                      validateStartAge(value);
                      setValue({ key: "startAge", value });
                    }}
                  />
                  {!state.validations.startAge &&
                    !state.validations.initial && (
                      <span style={{ color: "red" }}>
                        {"La edad desde es requerida"}
                      </span>
                    )}
                </InputGroup>
                <InputGroup className="w-50">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Hasta</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min={0}
                    value={state.endAge === -1 ? "" : state.endAge}
                    onChange={e => {
                      const value = parseInt(e.currentTarget.value);
                      validateEndAge(value);
                      setValue({ key: "endAge", value });
                    }}
                  />
                  {!state.validations.endAge && !state.validations.initial && (
                    <span style={{ color: "red" }}>
                      {"La edad hasta es requerida"}
                    </span>
                  )}
                </InputGroup>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Label className="fw-bold mt-4 font-weight-bold">
              {"Valores de Referencia "}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label>Tipo</Label>
              {state.analysisMethodTypes && (
                <ReactSelect
                  name="analysisMethodSelect"
                  // isDisabled={state.ampSelectedToUpdate === -1 ? false : true}
                  value={{
                    value: labelTipoSelected,
                    label: labelTipoSelected
                  }}
                  onChange={e => {
                    validateTypeSelected(e.value);
                    setValue({
                      key: "min",
                      value: ""
                    });
                    setValue({
                      key: "max",
                      value: ""
                    });
                    setValue({
                      key: "typeSelected",
                      value: Number(e.id)
                    });
                  }}
                  options={
                    state.products &&
                    state.analysisMethodTypes.map(product => ({
                      label: product.name,
                      value: product.name,
                      id: product.id
                    }))
                  }
                />
              )}
              {!state.validations.type && !state.validations.initial && (
                <span style={{ color: "red" }}>
                  {"Este campo es requerido"}
                </span>
              )}
            </FormGroup>
          </Col>
          {state.typeSelected !== -1 && (
            <>
              <Col className="" xs={1}>
                <FormGroup>
                  <Label>Mínimo</Label>
                  {getMarked({ forMin: true })}

                  {state.typeSelected !== 5 &&
                    !state.validations.minMax &&
                    !state.validations.initial && (
                      <span style={{ color: "red" }}>
                        {"Mínimo o máximo son requeridos"}
                      </span>
                    )}

                  {/* {!state.validations.minMax && !state.validations.initial && (
                    <span style={{ color: "red" }}>
                      {"Mínimo o máximo son requeridos"}
                    </span>
                  )} */}
                </FormGroup>
              </Col>
              <Col className="" xs={1}>
                <FormGroup>
                  <Label>Máximo</Label>
                  {getMarked({ forMin: false })}
                  {state.typeSelected !== 5 &&
                    !state.validations.minMax &&
                    !state.validations.initial && (
                      <span style={{ color: "red" }}>
                        {"Mínimo o máximo son requeridos"}
                      </span>
                    )}

                  {/* {!state.validations.minMax && !state.validations.initial && (
                    <span style={{ color: "red" }}>
                      {"Mínimo o máximo son requeridos"}
                    </span>
                  )} */}
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col xs={10}>
            <FormGroup>
              <Label for="interpretationLabel">Interpretación</Label>
              <Input
                type="textarea"
                name="intepretacion"
                rows={4}
                value={state.interpretation}
                onChange={e => {
                  setValue({
                    value: e.currentTarget.value,
                    key: "interpretation"
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={2} className="d-flex align-items-end">
            <FormGroup>
              <Button
                type="button"
                className="btn-gray-600"
                onClick={
                  state.ampSelectedToUpdate === -1
                    ? addNewProduct
                    : onUpdateAnalysisMethodProduct
                }
              >
                <i className="fa fa-plus mr-2"></i>
                {state.ampSelectedToUpdate === -1 ? "Agregar" : "Modificar"}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <ReactTable
              columns={columnsProductCache}
              defaultPageSize={5}
              data={dataProductCache()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <Button color="success" type="submit">
              {isUpdating ? "Modificar" : "Crear"}
            </Button>
          </Col>
        </Row>
      </Form>
    </TabPane>
  );
}

export default GeneralTab;
