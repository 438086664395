import React from "react";

const MainManagement = React.lazy(() => import("./pages"));

const routes = [
  {
    path: "/dashboard/chronic-patients",
    name: "Cartera de Pacientes Crónicos",
    component: MainManagement
  }
];

export default routes;
