import React, { Suspense } from "react";
import { useAppContext } from "../../AppContext";
import DefaultFooter from "../DefaultLayout/DefaultFooter";

const loading = () => {
  return <div className="animated fadeIn pt-1 text-center">{"Loading..."}</div>;
};

const ResultsHeader = React.lazy(() => import("./ResultsHeader"));
const ResultsBody = React.lazy(() => import("./ResultsBody"));

const ResultsLayout = () => {
  const { state: ctx } = useAppContext();
  return (
    <div className="app fadeIn animated">
      <Suspense fallback={loading()}>
        <ResultsHeader item={ctx} />
        <ResultsBody item={ctx} />
        {/* <DefaultFooter /> */}
      </Suspense>

      {/* <Suspense fallback={loading()}>
      </Suspense> */}
    </div>
  );
};

export default ResultsLayout;
