import React from "react";

const PaymentConditionMainList = React.lazy(() => import("./pages/list"));
const PaymentConditionMainManagement = React.lazy(() => import("./pages/management"));

const routes = [
  {
    exact: true,
    path: "/accounting/payment-condition-list",
    name: "Condiciones de pago",
    component: PaymentConditionMainList
  },
  {
    exact: true,
    path: "/accounting/payment-condition-list/new",
    name: "Nueva Condicion de pago",
    component: PaymentConditionMainManagement
  },
  {
    exact: true,
    path: "/accounting/payment-condition-list/edit/:id",
    name: "Editar Condicion de pago",
    component: PaymentConditionMainManagement
  },
];

export default routes;
