import schema40 from "./pages";

const routes = [
  {
    exact: true,
    path: "/dashboard/schema40",
    name: "Esquema 40 - SISALRIL",
    component: schema40
  }
];

export default routes;
