import { lazy } from "react";
const List = lazy(() => import("./pages/list"));
const Management = lazy(() => import("./pages/management"));
const routes = [
  {
    exact: true,
    path: "/shopping/purchase-conduce-relationship",
    name: "Recepción de Conduce",
    component: List
  },
  {
    exact: true,
    path: "/shopping/purchase-conduce-relationship/receive/:id",
    name: "Recepción de Conduce",
    component: Management
  }
];

export default routes;
