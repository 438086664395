import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import ReactTable from "react-table-v6";
import moment from "moment";
import { useActions } from "./useActions";
import ReactNumberFormat from "react-number-format";
import { ModalDetailsNoGenerate } from "./components/ModalDetailsNoGenerate";
import { sumFooterUtils } from "../../../../../../../../utils/utils";

const AverageTicketModalDetails = ({
  isOpen,
  toggle,
  item,
  startDate,
  endDate,
  appointmentName
}) => {
  const { state, actions } = useActions({ endDate, item, startDate });
  console.log({ item, state });

  return (
    <div className="animated fadeIn">
      {state.isOpen && (
        <ModalDetailsNoGenerate
          isOpen={state.isOpen}
          onChange={actions.onChange}
          item={state.itemSelected}
        />
      )}
      <Modal
        isOpen={isOpen}
        fullscreen="xl"
        size="xl"
        style={{ width: "90%" }}
        className=" mw-100"
      >
        <ModalHeader toggle={toggle}>
          Detalle de Ticket Promedio por Médico
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="3">
              <FormGroup>
                <Label>Médico</Label>
                <Input disabled value={item?.medicalStaffName || ""} />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label>Tipo de Cita</Label>
                <Input disabled value={appointmentName || ""} />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label>Período</Label>
                <Input
                  disabled
                  className="text-center"
                  value={`${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                    endDate
                  ).format("DD/MM/YYYY")}`}
                />
              </FormGroup>
            </Col>
            <Col sm="2">
              <FormGroup>
                <Label>Optimo</Label>
                <ReactNumberFormat
                  renderText={value => (
                    <div className="text-center"> {value} </div>
                  )}
                  prefix="$"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  displayType="input"
                  disabled
                  customInput={Input}
                  value={state?.data[0]?.expectedTicket || 0}
                  thousandSeparator={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <ReactTable
                data={state.data || []}
                defaultPageSize={10}
                loading={state.loading}
                loadingText="Cargando..."
                columns={[
                  {
                    Header: "Tipo de Producto",
                    accessor: "productType",
                    rowSpan: "2",
                    Footer: () => <div className="text-center">Total</div>
                  },
                  {
                    Header: "Valor Facturado",
                    columns: [
                      {
                        Header: "En Consulta",
                        className: "text-center",
                        accessor: "salesInAppointment",
                        Cell: ({ original: { salesInAppointment } }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={salesInAppointment}
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={sumFooterUtils(data, "salesInAppointment")}
                            thousandSeparator={true}
                          />
                        )
                      },
                      {
                        Header: "Generado",
                        className: "text-center",
                        accessor: "generate",
                        Cell: ({ original: { generate } }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={generate ? generate : 0}
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={sumFooterUtils(data, "generate")}
                            thousandSeparator={true}
                          />
                        )
                      },
                      {
                        Header: "Total",
                        className: "text-center",
                        accessor: "expectedTicket",
                        Cell: ({
                          original: { generate, salesInAppointment }
                        }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={generate + salesInAppointment}
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={
                              sumFooterUtils(data, "generate") +
                              sumFooterUtils(data, "salesInAppointment")
                            }
                            thousandSeparator={true}
                          />
                        )
                      }
                    ]
                  },
                  {
                    Header: "Vs. Optimo",
                    accessor: "optimum",
                    rowSpan: "2",
                    Cell: ({
                      original: { generate, salesInAppointment, expectedTicket }
                    }) => (
                      <ReactNumberFormat
                        renderText={value => (
                          <div className="text-center"> {value} </div>
                        )}
                        suffix="%"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                        value={
                          (((generate ? generate : 0) +
                            salesInAppointment -
                            expectedTicket) /
                            expectedTicket) *
                          100
                        }
                        thousandSeparator={true}
                      />
                    ),
                    Footer: ({ data }) => (
                      <ReactNumberFormat
                        renderText={value => (
                          <div className="text-center"> {value} </div>
                        )}
                        suffix="%"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                        value={
                          ((sumFooterUtils(data, "generate") +
                            sumFooterUtils(data, "salesInAppointment") -
                            state?.data[0]?.expectedTicket) /
                            state?.data[0]?.expectedTicket) *
                          100
                        }
                        thousandSeparator={true}
                      />
                    )
                  },
                  {
                    Header: "No Facturado",
                    columns: [
                      {
                        Header: "Valor",
                        className: "text-center",
                        accessor: "noGenerate",
                        Cell: ({ original: { noGenerate } }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={noGenerate || 0}
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={sumFooterUtils(data, "noGenerate")}
                            thousandSeparator={true}
                          />
                        )
                      },
                      {
                        Header: "Proporción",
                        className: "text-center",
                        accessor: "salesInAppointment",
                        Cell: ({
                          original: { noGenerate, generate, salesInAppointment }
                        }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            suffix="%"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={
                              noGenerate
                                ? (noGenerate /
                                    ((generate ? generate : 0) +
                                      salesInAppointment)) *
                                  100
                                : 0
                            }
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={
                              (sumFooterUtils(data, "noGenerate") /
                                (sumFooterUtils(data, "generate") +
                                  sumFooterUtils(data, "salesInAppointment"))) *
                              100
                            }
                            thousandSeparator={true}
                          />
                        )
                      }
                    ]
                  },
                  {
                    Header: "Opciones",
                    Cell: ({ original }) => (
                      <Button
                        // color="success"
                        onClick={() =>
                          actions.openAndSetItemToModal(
                            original,
                            appointmentName
                          )
                        }
                      >
                        Detalle No Facturado
                      </Button>
                    )
                  }
                ]}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="float-left">
          <Button color="success" onClick={() => toggle()}>
            {"Cerrar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AverageTicketModalDetails;
