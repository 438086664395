import gql from "graphql-tag";
import React from "react";
import { useHistory, useParams } from "react-router";
import ReactTable from "react-table-v6";
import { Button, Card, CardBody, Label } from "reactstrap";
import { get, sortBy } from "lodash";
import { useQuery } from "react-apollo-hooks";
import moment from "moment";
const query = gql`
  query getRegisteredPatientsByPrograms($where: WhereGetRegisteredPatient) {
    registeredPatientByProgram: getRegisteredPatientByProgram(where: $where) {
      patientId
      activationDate
      controlled
      noAffiliate
      patientName
      pamId
      date
    }
    programs: medicalAttentionPrograms {
      id
      name
    }
  }
`;

export default function Program() {
  const { programId, startDate, endDate } = useParams();

  const { data } = useQuery(query, {
    variables: {
      where: {
        programId: parseInt(programId),
        startDate,
        endDate
      }
    },
    fetchPolicy: "network-only"
  });

  const history = useHistory();

  const programName =
    data.programs && data.programs.find(p => p.id === parseInt(programId)).name;

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Label className="font-weight-bold m-2">{programName}</Label>
          <ReactTable
            data={sortBy(get(data, "registeredPatientByProgram", []), [
              "patientName"
            ])}
            defaultPageSize={10}
            columns={[
              {
                Header: "Paciente",
                accessor: "patientName"
              },
              {
                Header: "Afiliado/NSS",
                accessor: "noAffiliate",
                className: "text-center"
              },
              {
                Header: "Inicio",
                accessor: "activationDate",
                className: "text-center",
                Cell: ({ original: { activationDate } }) =>
                  moment(activationDate).format("DD-MM-YYYY")
              },
              {
                Header: "Estatus",
                Cell: ({ original }) => {
                  console.log({ original });
                  return (
                    <span>
                      {original.controlled ? "Controlado" : "No Controlado"}
                    </span>
                  );
                }
              },
              {
                Header: "Ultima Cita",
                className: "text-center",
                Cell: ({ original }) => {
                  console.log({ original });
                  return (
                    <span>{moment(original.date).format("DD-MM-YYYY")}</span>
                  );
                }
              },
              {
                Header: "Opciones",
                Cell: ({ original }) => (
                  <Button
                    onClick={() => {
                      history.push(
                        `/dashboard/performance-metrics-pyp/registered-patients/program/${programId}/startDate/${startDate}/endDate/${endDate}/patient/${original.patientId}`
                      );
                    }}
                    color="success"
                  >
                    {" "}
                    Ver detalle
                  </Button>
                )
              }
            ]}
          />
        </CardBody>
      </Card>
    </div>
  );
}
