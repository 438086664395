import React from "react";
import AdherenceRateTable from "./adherence-rate/TablePrograms";
import AdherenceRateByProgramChart from "./adherence-rate/ChartPrograms";
import RegisteredPatientsChart from "./registered-patients/ChartPrograms";
import RegisteredPatientsChartTable from "./registered-patients/TablePrograms";
import { Col, Row, FormGroup, Label, Spinner } from "reactstrap";
import useActions from "./useActions";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
export default function Index() {
  const { state, setState, loading } = useActions();
  return (
    <Row>
      <Col xs={12}>
        <Label className="font-weight-bold mt-2 mb-2">Período</Label>
        <Row>
          <Col xs={2}>
            <FormGroup className="d-flex flex-column">
              <Label for="exampleDate">Desde</Label>
              <DatePicker
                className="form-control"
                onSelect={e => setState(state => ({ ...state, startDate: e }))}
                onChange={e => setState(state => ({ ...state, startDate: e }))}
                selected={state.startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Selecciona una fecha hasta"
              />
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup className="d-flex flex-column">
              <Label for="exampleDate">Hasta</Label>
              <DatePicker
                className="form-control"
                onSelect={e => setState(state => ({ ...state, endDate: e }))}
                onChange={e => setState(state => ({ ...state, endDate: e }))}
                selected={state.endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Selecciona una fecha hasta"
              />
            </FormGroup>
          </Col>
          <Col xs={2}>{loading && <Spinner animation="grow" />}</Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Label className="font-weight-bold mt-2 mb-4">
          Pacientes Registrados
        </Label>
        <RegisteredPatientsChartTable state={state} setState={setState} />
      </Col>
      <Col xs={6}>
        <RegisteredPatientsChart state={state} />
      </Col>
      <Col xs={6}>
        <Label className="font-weight-bold mt-4 mb-4">Tasa de Adherencia</Label>
        <AdherenceRateTable state={state} />
      </Col>
      <Col xs={6} className="mt-5">
        <AdherenceRateByProgramChart state={state} />
      </Col>
    </Row>
  );
}
