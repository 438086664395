import React from "react";
const ProviderMainList = React.lazy(() => import("./pages/list"));
const ProviderMainManagement = React.lazy(() => import("./pages/management"));
const routes = [
  {
    exact: true,
    path: "/accounting/providers-list",
    name: "Provedores",
    component: ProviderMainList
  },
  {
    exact: true,
    path: "/accounting/providers-list/new",
    name: "Nuevo Provedor",
    component: ProviderMainManagement
  },
  {
    exact: true,
    path: "/accounting/providers-list/edit/:id",
    name: "Editar Provedor",
    component: ProviderMainManagement
  },
];

export default routes;
