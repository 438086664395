import React, { Component } from "react";
import PropTypes from "prop-types";
import polpoLogo from "../../assets/img/brand/polpo-blanco.png";
const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span>&copy; Polpo, S.R.L.</span>
          <div className="d-flex justify-content-end align-items-center w-75">
            <span className="mr-3">Powered by</span>
            <img style={{ width: "50px" }} src={polpoLogo} alt="" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
