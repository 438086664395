import { lazy } from "react";

const MainPage = lazy(() => import("./page"));
const ManagementPage = lazy(() => import("./page/management"));

const routes = [
  {
    path: "/management/diagnostic-group",
    component: MainPage,
    exact: true,
    name: "Grupos de Enfermedades - CIE"
  },
  {
    path: "/management/diagnostic-group/new",
    component: ManagementPage,
    exact: true,
    name: "Nuevo Grupo de Enfermedades - CIE"
  },
  {
    path: "/management/diagnostic-group/edit/:id",
    component: ManagementPage,
    exact: true,
    name: "Editar Grupo de Enfermedades - CIE"
  }
];

export default routes;
