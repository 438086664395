import gql from "graphql-tag";

export const GET_NO_GENERATE_DETAILS = gql`
  query getNoGenerateDetails($where: WhereAverageAndTicket) {
    getNoGenerateDetails(where: $where) {
      id
      product
      quantity
      amount
      copago
    }
  }
`;
