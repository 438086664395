import React from "react";

const MainManagement = React.lazy(() => import("./pages/management"));

const routes = [
  {
    path: "/operations/appointment-histories",
    name: "Historia Clínica",
    component: MainManagement
  },
  {
    path: "/operations/appointment-histories/:identification",
    name: "Historia Clínica",
    component: MainManagement
  }
];

export default routes;
