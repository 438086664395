import React from "react";

const MainManagement = React.lazy(() => import("./pages/management"));

export const getWharehouseTypeLabel = id => {
  return { 1: "Regular", 2: "Vencidos", 3: "Mal Estado" }[parseInt(id)];
};

const routes = [
  {
    path: "/dashboard/inventoryy",
    name: "Niveles de inventario",
    component: MainManagement
  }
];

export default routes;
