import React from "react";
const list = React.lazy(() => import("./pages/list"));
const management = React.lazy(() => import("./pages/management"));
const Detail = React.lazy(() => import("./pages/details"));

const routes = [
  {
    name: "Devoluciones Regulares",
    path: "/regular-return",
    component: list,
    exact: true
  },
  {
    name: "Captura de Devolución de Compras",
    path: "/regular-return/new",
    component: management,
    exact: true
  },
  {
    name: "Detalle de Devolución de Compras",
    path: "/regular-return/detail/:id",
    component: Detail,
    exact: true
  }
];

export default routes;
