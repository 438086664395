import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import ReactTable from "react-table-v6";
import moment from "moment";
import { useActions } from "./useActions";
import ReactNumberFormat from "react-number-format";

const AverageTimeModalDetails = ({
  isOpen,
  toggle,
  item,
  startDate,
  endDate
}) => {
  const { state } = useActions({ endDate, item, startDate });
  console.log({ item, state });

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} size={"xl"}>
        <ModalHeader toggle={toggle}>Detalle de Tiempo de Atención</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label>Médico</Label>
                <Input disabled value={item?.medicalStaffName || ""} />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label>Centro</Label>
                <Input disabled value={item?.center || ""} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <ReactTable
                data={state.data || []}
                defaultPageSize={10}
                loading={state.loading}
                loadingText="Cargando..."
                columns={[
                  {
                    Header: "Fecha",
                    accessor: "date",
                    Cell: ({ original }) => {
                      return (
                        <div className="text-center">
                          <span>
                            {moment(original.date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    Header: "Paciente",
                    accessor: "patient"
                  },
                  {
                    Header: "Inicio",
                    accessor: "start",
                    Cell: ({ original }) => {
                      return (
                        <div className="text-center">
                          <span>
                            {original.start &&
                              moment(original.start).format(
                                "DD-MM-YYYY / hh:mm:ss a"
                              )}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    Header: "Fin",
                    accessor: "end",
                    Cell: ({ original }) => {
                      return (
                        <div className="text-center">
                          <span>
                            {original.end &&
                              moment(original.end).format(
                                "DD-MM-YYYY / hh:mm:ss a"
                              )}
                          </span>
                        </div>
                      );
                    }
                  },
                  {
                    Header: "Tiempo",
                    accessor: "time",
                    className: "text-center"
                  },
                  {
                    Header: "Vs. Optimo",
                    accessor: "optimum",
                    Cell: ({ original: { optimum } }) => (
                      <ReactNumberFormat
                        renderText={value => (
                          <div className="text-center"> {value} </div>
                        )}
                        suffix="%"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                        value={optimum}
                        thousandSeparator={true}
                      />
                    )
                  }
                ]}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="float-left">
          <Button color="success" onClick={() => toggle()}>
            {"Cerrar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AverageTimeModalDetails;
