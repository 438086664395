import { lazy } from "react";

const Index = lazy(() => import("./pages/list"));
const Management = lazy(() => import("./pages/management"));

const routes = [
  {
    name: "Consulta de Precios",
    component: Index,
    exact: true,
    path: "/dashboard/price-inquiry"
  },
  {
    name: "Precios por Aseguradoras",
    component: Management,
    exact: true,
    path: "/dashboard/price-inquiry/insurance-carrier/:id"
  }
];

export default routes;
