import React from "react";
import ReactTable from "react-table-v6";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import ReactNumberFormat from "react-number-format";
import moment from "moment";

export default function Table({ state }) {
  const history = useHistory();

  const goToDetail = original =>
    history.push(
      `/dashboard/performance-metrics-regular/detail-average-time/appointment-type-id/${
        original.appointmentId
      }/startDate/${moment(state.startDate).format(
        "YYYY-MM-DD"
      )}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}`
    );

  return (
    <div>
      <ReactTable
        data={state.averageTime}
        defaultPageSize={10}
        columns={[
          {
            Header: "Tipo de Cita",
            Cell: ({ original }) => (
              <div classname="text-right">{original.appointmentName}</div>
            )
          },
          {
            Header: "Optimo (Min)",
            Cell: ({ original }) => {
              return (
                <ReactNumberFormat
                  renderText={value => (
                    <div className="text-center"> {value} </div>
                  )}
                  displayType="text"
                  value={original.expectedDuration}
                  thousandSeparator={true}
                />
              );
            }
          },
          {
            Header: "Promedio (Min)",
            accessor: "averageDuration",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-center"> {value} </div>
                )}
                decimalScale={2}
                displayType="text"
                value={original.averageDuration}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Desviación",
            Cell: ({ original: { averageDuration, expectedDuration } }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-center"> {value} </div>
                )}
                suffix="%"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={
                  ((averageDuration - expectedDuration) / expectedDuration) *
                  100
                }
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Opciones",
            Cell: ({ original }) => (
              <Button onClick={() => goToDetail(original)} color="success">
                {" "}
                Ver detalle
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
