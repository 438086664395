import { lazy } from "react";
const PurchaseOrderRelationList = lazy(() => import("./pages/list/index"));
const GeneralStep = lazy(() => import("./pages/management/general-step"));

const routes = [
  {
    exact: true,
    path: "/shopping/purchase-order-approval-provider",
    name: "Compras Proveedores - Aprobación",
    component: PurchaseOrderRelationList
  },
  {
    exact: true,
    path: "/shopping/purchase-order-approval/approve-provider/:numOrder",
    name: "Aprobacion de Orden de Compra",
    component: GeneralStep
  },
  {
    exact: true,
    path: "/shopping/purchase-order-approval-supplier",
    name: "Compras Suplidores - Aprobación",
    component: PurchaseOrderRelationList
  },
  {
    exact: true,
    path: "/shopping/purchase-order-approval/approve-supplier/:numOrder",
    name: "Aprobacion de Orden de Compra",
    component: GeneralStep
  }
];

export default routes;
