import React from "react";

import nav from "./_nav";
import get from "lodash/get";
import map from "lodash/map";
import gql from "graphql-tag";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import history from "@meds/shared/history";
import createApolloClient from "@meds/shared/apollo-client";

import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Router, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { PrivateRoute } from "./containers";

import { AppProvider } from "./AppContext";
import { useQuery } from "react-apollo-hooks";
import { Loading } from "./components/Loading";
import { useAuth0 } from "@meds/contexts/react-auth0-spa";

import "./App.scss";

const GET_PROFILE = gql`
  query GetProfile($where: ProfileWhereUniqueInput!) {
    profile: getProfile(where: $where) {
      profile {
        id
        name
        isCenterAdmin

        views {
          name
        }
      }

      user {
        profileIdPatient
        profileId
        id
        email
        code
        name
        identification
        centers {
          id
          name
        }
        arses {
          id
          # ars {
          # id
          # name
          # }
        }
      }

      options {
        id
        value
      }
    }
  }
`;

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

//Layout de los Resultados
const ResultsLayout = React.lazy(() =>
  import("./containers/ResultsLayout/ResultsLayout")
);
//Layout para las ARS
const ARSLayout = React.lazy(() => import("./containers/ARSLayout/ARSLayout"));
//Layout para la Pantalla de Pacientes en Espera
const AwaitBody = React.lazy(() =>
  import("./containers/PatientAwaitLayout/AwaitBody")
);

const ModalProfileLayout = React.lazy(() =>
  import("./containers/ModalProfileLayout/ModalProfileLayout")
);

//Layout pantalla de check-in
const CheckIn = React.lazy(() => import("./containers/CheckIn"));

function AppRoute({ loading, user }) {
  let metadata = {};
  const option = {};
  const permissionViews = {};
  const navigation = { items: [] };
  let profileId = null;
  let profileIdPatient = null;

  const { loading: ploading, data: { profile } = {} } = useQuery(GET_PROFILE, {
    skip: loading || !user,
    fetchPolicy: "network-only",
    variables: { where: { usersub: get(user, "sub") } }
  });

  if (!loading && !ploading) {
    forEach(get(profile, "profile.views", []), pv => {
      permissionViews[pv.name] = true;
    });

    forEach(get(profile, "options"), item => {
      option[item.id] = item.value;
    });

    profileId = get(profile, "profile.id", null);
    profileIdPatient = get(profile, "user.profileIdPatient", null);

    navigation.items = filter(
      map(nav.items, item => {
        const children = filter(
          item.children,
          ic => permissionViews[ic.permission]
        );
        return { ...item, children };
      }),
      item => item.children.length > 0
    );

    if (!!user) {
      metadata = user["https://app2.meds.com.do/user_metadata"];
    }
  }

  window.print = console.log;

  const LayoutReturn = key => {
    const sessionProfile = JSON.parse(
      sessionStorage.getItem("profileSelected")
    );

    const keys = key;

    if (!!sessionProfile) {
      switch (sessionProfile?.id) {
        case 16:
          return ARSLayout;
        case 997:
          return CheckIn;
        case 998:
          return AwaitBody;
        case 999:
          return ResultsLayout;
        default:
          return DefaultLayout;
      }
    }

    if (!!profileId && !!profileIdPatient) {
      return ModalProfileLayout;
    }

    switch (keys) {
      case 16:
        return ARSLayout;
      case 997:
        return CheckIn;
      case 998:
        return AwaitBody;
      case 999:
        return ResultsLayout;
      default:
        return DefaultLayout;
    }
  };
  console.log("el perfil");
  console.log(profile);

  return (
    <div>
      {(loading || ploading) && <Loading />}
      {!loading && !ploading && (
        <AppProvider
          value={{
            option,
            profileId,
            metadata,
            navigation,
            permissionViews,
            user: {
              ...get(profile, "user", null),
              profile: get(profile, "profile", null)
            },
            center: get(metadata, "centers[0]", null)
          }}
        >
          <Router history={history}>
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <PrivateRoute
                  path="/"
                  name="Home"
                  // component={profileId === 999 ? ResultsLayout : DefaultLayout}
                  component={LayoutReturn(
                    profileId ? profileId : profileIdPatient
                  )}
                />
              </Switch>
            </React.Suspense>
          </Router>
        </AppProvider>
      )}
    </div>
  );
}

export default function App() {
  const { loading, user, logout, getTokenSilently } = useAuth0();
  const apolloClient = createApolloClient({
    logout,
    getTokenSilently,
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI
  });

  console.log({ user });

  return (
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <AppRoute loading={loading} user={user} />
      </ApolloHooksProvider>
    </ApolloProvider>
  );
}
