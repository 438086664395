import { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { GET_AVERAGE_TIME_BY_APPOINTMENT_AND_STAFF_AND_CENTER_DETAILS } from "./queries";

const INITIAL_STATE = {
  data: [],
  loading: true
};

export const useActions = ({ item, startDate, endDate }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const { data, loading } = useQuery(
    GET_AVERAGE_TIME_BY_APPOINTMENT_AND_STAFF_AND_CENTER_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          appointmentTypeId: item.appointmentId,
          centerId: item.centerId,
          staffId: item.medicalStaffId,
          startDate,
          endDate
        }
      }
    }
  );

  console.log({ data, loading });

  useEffect(() => {
    if (data && !loading) {
      setState(prev => ({
        ...prev,
        data: data?.getAverageTimeByAppointmentAndStaffAndCenterDetails || [],
        loading
      }));
    }
  }, [data, loading]);

  return {
    state,
    actions: {}
  };
};
