import gql from "graphql-tag";
import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { Button, Card, CardBody, Label } from "reactstrap";
import { useParams } from "react-router";
import ReactNumberFormat from "react-number-format";
import { Line } from "react-chartjs-2";
import Chart from "./ChartLine";
import AverageTicketModalDetails from "./components/ModalDetails";

const QUERY = gql`
  query getAverageTicketByAppointmentByMedicalStaff(
    $where: WhereAverageAndTicket
  ) {
    getAverageTicketDetails: getAverageTicketByAppointmentTypeDetails(
      where: $where
    ) {
      expectedTicket
      appointmentName
      averageTicket
      appointmentId
      medicalStaffId
      medicalStaffName
      centerId
      center
    }
    averageTicketByAppointmentByMedicalStaffByMonth: getAverageTicketByAppointmentByMedicalStaffByMonth(
      where: $where
    ) {
      name
      msId
      pId
      month
      expectedValue
      averageTicket
    }
  }
`;
export default function Table() {
  const {
    appointmentTypeId,
    startDate,
    endDate,
    appointmentName
  } = useParams();
  const { data } = useQuery(QUERY, {
    variables: {
      where: {
        appointmentTypeId: parseInt(appointmentTypeId),
        startDate,
        endDate
      }
    },
    fetchPolicy: "network-only"
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="animated fadeIn">
      {isOpen && (
        <AverageTicketModalDetails
          isOpen={isOpen}
          toggle={toggle}
          item={selected}
          startDate={startDate}
          endDate={endDate}
          appointmentName={appointmentName}
        />
      )}
      <Card>
        <CardBody>
          <Label className="font-weight-bold">{appointmentName}</Label>
          <ReactTable
            data={data?.getAverageTicketDetails || []}
            defaultPageSize={10}
            columns={[
              {
                Header: "Médico",
                accessor: "medicalStaffName"
              },
              // {
              //   Header: "Centro",
              //   accessor: "center"
              // },
              {
                Header: "Optimo",
                Cell: ({ original }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-right"> {value} </div>
                    )}
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                    value={original.expectedTicket}
                    thousandSeparator={true}
                  />
                )
              },
              {
                Header: "Promedio",
                Cell: ({ original }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-right"> {value} </div>
                    )}
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                    value={original.averageTicket}
                    thousandSeparator={true}
                  />
                )
              },
              {
                Header: "Desviación",
                // accessor: "status",
                Cell: ({ original: { averageTicket, expectedTicket } }) => (
                  <div className="text-center">
                    <ReactNumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix="%"
                      decimalScale={2}
                      value={
                        ((averageTicket - expectedTicket) / expectedTicket) *
                        100
                      }
                    />
                  </div>
                )
              },
              {
                Header: "Opciones",
                Cell: ({ original }) => (
                  <Button
                    onClick={() => {
                      toggle();
                      setSelected(original);
                    }}
                    color="success"
                  >
                    Ver detalle
                  </Button>
                )
              }
            ]}
          />
          <div className="mt-2">
            <Chart state={data} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
