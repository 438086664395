import React from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import Button from "reactstrap/lib/Button";
import ReactTable from "react-table-v6";
import Col from "reactstrap/lib/Col";
import StatusButton from "../../../../components/molecules/status-button";
import { useHistory } from "react-router";
import { Row } from "reactstrap";
import useActions from "./use-actions";
import { TOGGLE_ENABLED } from "./queries";

export default function Index() {
  const {
    state,
    actions: { refetch }
  } = useActions();
  const history = useHistory();
  function columns() {
    return [
      {
        accessor: "name",
        Header: "Método"
      },
      {
        Header: "Opciones",
        accessor: "options",

        Cell: ({ original }) => (
          <FormGroup>
            <Button
              type="button"
              className="btn-gray-600 card-header-action"
              onClick={e => {
                const name =
                  e.currentTarget.parentElement.parentElement.parentElement
                    .children[0].textContent;
                const getIdByName = () =>
                  state.items.find(e => e.name === name);
                const id = getIdByName().id;
                history.push(`/administration/analysis-methods/edit/${id}`);
              }}
            >
              <i className="fa fa-pencil"></i>
              {" Editar"}
            </Button>
            <StatusButton
              itemId={original.id}
              status={original.enabled}
              mutate={TOGGLE_ENABLED}
              onCompleted={() => refetch()}
            />
          </FormGroup>
        )
      }
    ];
  }

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Row>
            <Col xs="12">
              <FormGroup className="d-flex justify-content-around">
                <Input
                  className="w-75 d-inline"
                  placeholder="Buscar Método de Análisis"
                  onChange={e => refetch(e.currentTarget.value)}
                />
                <div className="ml-3 w-25">
                  <Button
                    type="button"
                    className="btn-gray-600"
                    onClick={() => {
                      history.push("/administration/analysis-methods/new");
                    }}
                  >
                    <i className="fa fa-plus mr-2"></i>
                    {"Agregar"}
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col xs="12">
              <ReactTable
                data={
                  [
                    ...state.items
                  ] /* .sort((a, b) =>
                    a.enabled === true ? -1 : 1
                  ) */ ||
                  []
                }
                loading={state.loadingTable}
                columns={columns()}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
