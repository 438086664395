import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { getValue } from "./collection";
export const usePopulateState = ({
  setState,
  query,
  variables,
  customFunction
}) => {
  const { data, loading, refetch } = useQuery(query, {
    variables,
    fetchPolicy: "network-only"
  });

  const memoizedCustomFunction = useCallback(customFunction, []);
  useEffect(() => {
    if (data && !loading) {
      setState(state => {
        // console.log({ state });
        return memoizedCustomFunction
          ? memoizedCustomFunction({ data, state })
          : { ...state, ...data };
      });
    }
  }, [
    data,
    loading,
    memoizedCustomFunction,
    setState
    // ...(dependencies || []),
  ]);

  return { loading, refetch };
};

export const ConditionalRender = ({ canRender = true, children }) => (
  <> {canRender && <div>{children}</div>} </>
);

export const useStateReal = initialState => {
  const [state, setState] = React.useState(initialState);

  return [
    state,
    fn =>
      setState(state => ({
        ...state,
        ...(typeof fn === "function" ? fn(state) : fn)
      }))
  ];
};

export const ReactSelectOnChangeBuilder = setState => keyname => val =>
  setState(state => ({ ...state, [keyname]: val }));

export const useTextInput = ({
  setState,
  middleware = ({ key, value, prevState, state }) => {},
  onEffect = () => {},
  initialState = {},
  path
}) => {
  const [localState, setLocalState] = React.useState(initialState);
  const onChangeFn = keyname => evt => {
    const value = path ? getValue(evt, path) : evt.currentTarget.value;
    const next = () => {
      setState(state => ({ ...state, [keyname]: value }));
      setLocalState(state => ({ ...state, [keyname]: value }));
    };

    const res = middleware({
      key: keyname,
      value,
      prevState: localState,
      state: { ...localState, [keyname]: value }
    });
    if (res === false) {
    } else if (res === true || isNil(res)) {
      next();
    }
  };

  React.useEffect(() => {
    onEffect({ state: localState });

    /* eslint-disable */
  }, [localState]);
  /* eslint-enable */
  return onChangeFn;
};

export const useReactSelectonChange = ({
  setState,
  middleware = () => {},
  onEffect = () => {},
  initialState = {}
}) => {
  const [localState, setLocalState] = React.useState(initialState);
  const onChangeFn = keyname => value => {
    const next = () => {
      setState(state => ({ ...state, [keyname]: value }));
      setLocalState(state => ({ ...state, [keyname]: value }));
    };

    middleware({
      key: keyname,
      value,
      prevState: localState,
      state: { ...localState, [keyname]: value }
    });
    next();
  };

  React.useEffect(() => {
    onEffect({ state: localState });

    /* eslint-disable */
  }, [localState]);
  /* eslint-enable */
  return onChangeFn;
};

export const TabComponent = ({
  config,
  defaultTabId,
  onChangeTab,
  ownProps = {}
}) => {
  const [state, setState] = useState({
    activeTabId: defaultTabId || config[0].id
  });
  return (
    <div {...ownProps}>
      <Nav tabs={true}>
        {" "}
        {config.map(({ id, header }) => (
          <NavItem className={""}>
            <NavLink
              active={state.activeTabId === id}
              onClick={() => {
                onChangeTab && onChangeTab(id);
                setState({ activeTabId: id });
              }}
            >
              {header}
            </NavLink>
          </NavItem>
        ))}{" "}
      </Nav>
      <TabContent activeTab={state.activeTabId}>
        {config.map(x => (
          <TabPane tabId={x.id}>{x.component}</TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export const TaxIdentificationInput = props => {
  const taxIdentificationFormat = ({ identification, isCedula }) =>
    identification
      .trim()
      .split("")
      .map((x, index) =>
        (isCedula ? [3, 10] : [1, 3, 8]).includes(index) ? `-${x}` : x
      )
      .join("");

  const onChange = evt => {
    let {
      currentTarget: { value }
    } = evt;

    value = value.trim().replaceAll("-", "");
    if (value.length > 11) return;
    const isCedula = value.length > 9;
    const formated = taxIdentificationFormat({
      isCedula,
      identification: value
    });
    props.onChange &&
      props.onChange({ ...evt, currentTarget: { value: formated } });
  };

  return props.customInput ? (
    <props.customInput {...props} onChange={onChange}></props.customInput>
  ) : (
    <input {...props} onChange={onChange}></input>
  );
};

export const onChangeState = (path, value, setState) => {
  setState(prev => ({
    ...prev,
    [path]: value
  }));
};
