import React from "react";
import { Line } from "react-chartjs-2";

export default function Chart({ state }) {
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const realData =
    state?.getAverageTimeByAppointmentByMedicalStaffByMonth || [];

  const datasets = realData
    .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)
    .map(e => {
      const data = realData.filter(f => f.name === e.name);

      const t = [];
      for (let i = 1; i <= 12; i++) {
        const f = data.find(d => d.month === i);
        t.push(f ? f.averageDuration : 0);
      }
      const color = getRandomColor();
      return {
        backgroundColor: color,
        data: t,
        fill: false,
        borderColor: color,
        borderWidth: 5,
        label: e.name
      };
    });

  console.log(datasets);

  let Meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre"
  ];

  return (
    <Line
      data={{
        // labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        labels: Meses,
        datasets
      }}
      options={{
        title: {
          display: true,
          text: "Tendencia de Tiempo Promedio por Cita",
          fontSize: 16
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function(value) {
                  return value % 1 === 0 ? Math.trunc(value) : null;
                }
              }
            }
          ]
        }
      }}
    />
  );
}
