import React from "react";

const MainManagement = React.lazy(() => import("./pages/list"));

const routes = [
  {
    path: "/dashboard/patients-attended",
    name: "Pacientes Atendidos - MISPAS",
    component: MainManagement
  }
];

export default routes;
