import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Row, Col, Button, Label } from "reactstrap";
import generateSenasaPdf from "./generate-pdf/senasa";
import generateOtherArs from "./generate-pdf/otherArs";
import AffiliateAttendedByRegimen from "./chart/AffiliatesAttendedByRegimen";
import AfflitiatesAttendedByGender from "./chart/AfflitiatesAttendedByGender";
import TypeQuerys from "./chart/TypeQuerys";
import ReactSelect from "react-select";
import CoverageType from "./chart/CoverageType";
import AffiliatesByControlled from "./chart/AffiliatesByControlled";
import useActions from "./useActions";
import moment from "moment";
import { Spinner } from "reactstrap";
import arsPamsReport from "./generate-excel/ars-pams-report";
import { Loading } from "../../../../components/Loading";
const OptionalRender = ({ canRender, children }) => (
  <div>{canRender && children}</div>
);
function getR({ state, onChangeValue, chartsLoaded }) {
  if (!state.data) return [];

  return state.data.programs.map(p => {
    return (
      <AffiliatesByControlled
        dataForCharts={p}
        onChangeValue={onChangeValue}
        chartsLoaded={chartsLoaded}
      />
    );
  });
}

export default function Index({ ars }) {
  const { state, onChangeValue, managementDownload, loading } = useActions(ars);
  const [downloadValidate, setDownloadValidate] = useState(true);
  const { data } = state;
  const { chartsLoaded } = state;
  // let downloadValidate = true
  // console.log({ state });
  function launchReport() {
    console.log(chartsLoaded);
    console.log({ state, data });

    onChangeValue("validate", false);

    const isSenasaSelected = state.arsSelected.value === 1025;
    if (isSenasaSelected) {
      generateSenasaPdf({ state, data });
    } else {
      generateOtherArs({ state, data });
    }
  }

  console.log(ars);
  // useEffect(() => {
  //   generateSenasaPdf(require("./ex.json"));
  // }, []);
  console.log(state);

  const printArsPamsReport = async () => {
    // onChangeValue('downloadValidate', false)

    const { getAnaliticDetailsForARS, validate } = await managementDownload();
    console.log(getAnaliticDetailsForARS);

    if (validate) {
      // onChangeValue('downloadValidate', false)
      console.log(state);
      // downloadValidate = true
      const { code, ars } = getAnaliticDetailsForARS?.analitics[0];
      arsPamsReport({ items: getAnaliticDetailsForARS, item: { ars, code } });
      setDownloadValidate(true);
    } else {
      console.log("data");
    }
  };

  console.log(downloadValidate);
  return (
    <div className="">
      <OptionalRender canRender={loading}>
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            backgroundColor: "#E4E5E6",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spinner color="primary" type="grow">
            {" "}
            Loading...
          </Spinner>
        </div>
      </OptionalRender>
      <Card>
        <CardBody>
          <Row>
            <Col xs={2}>
              <Label>ARS</Label>
              <ReactSelect
                isDisabled={state.selectArsEnabled}
                value={state.arsSelected}
                options={
                  /* state.arses?.map(ars => ({
                  label: ars.name,
                  value: parseInt(ars.id)
                })) */ state.arses2
                }
                onChange={e =>
                  onChangeValue({
                    key: "arsSelected",
                    value: {
                      label: e.label,
                      value: e.value
                    }
                  })
                }
              />
            </Col>
            <Col xs={2}>
              <Label>Fecha</Label>
              <ReactSelect
                value={state.periodSelected}
                options={state.periods?.map(period => ({
                  label: `${moment(period.date).format("DD-MM-YYYY")} - ${
                    period.ncf
                  }`,
                  value: period.billingId
                }))}
                onChange={e =>
                  onChangeValue({
                    key: "periodSelected",
                    value: {
                      label: e.label,
                      value: e.value
                    }
                  })
                }
              />
            </Col>
            <Col xs={2} className="d-flex flex-column justify-content-end">
              <Button
                disabled={!state.validate || !downloadValidate}
                color="success"
                onClick={() => {
                  printArsPamsReport();
                  // downloadValidate = false
                  setDownloadValidate(false);
                }}
              >
                {downloadValidate ? (
                  <>
                    <i className="fa fa-file-excel-o mr-2"></i>{" "}
                    {"Descargar en Excel"}
                  </>
                ) : (
                  <Spinner type="grow"> </Spinner>
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Button
                disabled={state.data ? false : true}
                color="success"
                className="mt-3"
                onClick={launchReport}
              >
                Generar
              </Button>
            </Col>
            <Col xs={12}>
              {data?.programs && (
                <>
                  <AffiliateAttendedByRegimen
                    dataForCharts={data}
                    onChangeValue={onChangeValue}
                    chartsLoaded={chartsLoaded}
                  />
                  <AfflitiatesAttendedByGender
                    dataForCharts={data}
                    onChangeValue={onChangeValue}
                    chartsLoaded={chartsLoaded}
                  />
                  <TypeQuerys
                    dataForCharts={data}
                    onChangeValue={onChangeValue}
                    chartsLoaded={chartsLoaded}
                  />
                  <CoverageType
                    dataForCharts={data}
                    onChangeValue={onChangeValue}
                    chartsLoaded={chartsLoaded}
                  />
                  {getR({ state, onChangeValue, chartsLoaded })}
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
