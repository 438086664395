import React, { useState, useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";

export default function TypeQuerys({
  dataForCharts,
  onChangeValue,
  chartsLoaded
}) {
  const realRef = useRef();
  if (realRef.current) {
    console.log("f");
    const f = realRef.current.chartInstance.ctx;
    f.save();
    f.globalCompositeOperation = "destination-over";
    f.fillStyle = "#EEEEEE";
    f.fillRect(
      0,
      0,
      realRef.current.chartInstance.width,
      realRef.current.chartInstance.height
    );
    f.restore();
  }
  const [hideChart, setHiddeChart] = useState(false);

  const countNewPypQuery = dataForCharts.new;
  const countFollowingPypQuery = dataForCharts.following;

  useEffect(() => {
    // when the chart load get us the base64image

    const img =
      realRef.current && realRef.current.chartInstance.toBase64Image();
    const f =
      countNewPypQuery === 0 && countFollowingPypQuery === 0 ? "NO-DATA" : img;
    if (chartsLoaded.typeQuerys !== f) {
      onChangeValue({
        key: "chartsLoaded",
        value: {
          ...chartsLoaded,
          typeQuerys: f
        }
      });
    }
    // this is for hidde the chart
    if (chartsLoaded.typeQuerys) {
      setHiddeChart(true);
    }
  }, [
    realRef,
    onChangeValue,
    chartsLoaded,
    countNewPypQuery,
    countFollowingPypQuery
  ]);

  const data = {
    datasets: [
      {
        backgroundColor: "#6F6C6A",
        data: [countNewPypQuery],
        label: `TOTAL CONSULTAS PYP PRIMERA VEZ (${countNewPypQuery})`
      },
      {
        backgroundColor: "#52290F",
        data: [countFollowingPypQuery],
        label: `TOTAL CONSULTAS PYP SEGUIMIENTO (${countFollowingPypQuery})`
      }
    ]
  };

  const options = {
    legend: {
      // position: 'left',
      align: "start",
      labels: {
        fontSize: 40
      }
    },
    defaultFontSize: 0,
    indexAxis: "x",
    animation: {
      duration: 0
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value, index, values) {
              return value % 1 === 0 ? value : null;
            },
            // margin: 35,
            fontSize: 40
          }
        }
      ]
    },
    maintainAspectRatio: false
    // responsive: true
  };

  return (
    <div hidden={hideChart}>
      <Bar height={600} options={options} data={data} ref={realRef} />
    </div>
  );
}
