import { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { GET_AVERAGE_TICKET_DETAILS_FOR_STAFF } from "./queries";

const INITIAL_STATE = {
  data: [],
  isOpen: false,
  itemSelected: {},
  loading: true
};

export const useActions = ({ item, startDate, endDate }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const { data, loading } = useQuery(GET_AVERAGE_TICKET_DETAILS_FOR_STAFF, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        appointmentTypeId: item.appointmentId,
        staffId: item.medicalStaffId,
        startDate,
        endDate
      }
    }
  });

  const onChange = (path, value) => {
    setState(prev => ({
      ...prev,
      [path]: value
    }));
  };

  const openAndSetItemToModal = (original, appointmentName) => {
    console.log({ original, item });
    //   {
    //     "original": {
    //         "productTypeId": 2,
    //         "productType": "Citas Médicas",
    //         "salesInAppointment": 127860.01,
    //         "expectedTicket": 700,
    //         "generate": 600,
    //         "noGenerate": null
    //     },
    //     "item": {
    //         "expectedTicket": 700,
    //         "appointmentName": null,
    //         "averageTicket": "391.9328993055555556",
    //         "appointmentId": 4,
    //         "medicalStaffId": 7,
    //         "medicalStaffName": "Leynell Tejada Ureña",
    //         "centerId": null,
    //         "center": null
    //     }
    // }

    const productTypes = state.data.map(item => ({
      productTypeId: item.productTypeId,
      productType: item.productType
    }));

    const itemSelected = {
      item,
      original,
      productTypes,
      startDate,
      endDate,
      appointmentName
    };

    onChange("itemSelected", itemSelected);
    onChange("isOpen", true);
  };

  useEffect(() => {
    if (data && !loading) {
      setState(prev => ({
        ...prev,
        data: data?.getAverageTicketDetailsForStaff || [],
        loading
      }));
    }
  }, [data, loading]);

  return {
    state,
    actions: {
      onChange,
      openAndSetItemToModal
    }
  };
};
