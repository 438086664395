import { lazy } from "react";
const List = lazy(() => import("./pages/list"));
const GeneralStep = lazy(() => import("./pages/management"));
const OfferDetails = lazy(() => import("./pages/management/offer"));

const routes = [
  {
    exact: true,
    path: "/shopping/purchase-order-reception-provider",
    name: "Compras Proveedores - Recepción",
    component: List
  },
  {
    exact: true,
    path: "/shopping/purchase-order-reception/reception-provider",
    name: "Recepcion de Orden de Compra",
    component: GeneralStep
  },
  {
    exact: true,
    path: "/shopping/purchase-order-reception-supplier",
    name: "Compras Suplidores - Recepción",
    component: List
  },
  {
    exact: true,
    path: "/shopping/purchase-order-reception/reception-supplier",
    name: "Recepcion de Orden de Compra",
    component: GeneralStep
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-reception/detail-offer/provider/:orderId/:numOrder",
    component: OfferDetails
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-reception/detail-offer/supplier/:orderId/:numOrder",
    component: OfferDetails
  }
];

export default routes;
