import React, { useState, useRef, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";

export default function CoverageType({
  dataForCharts,
  onChangeValue,
  chartsLoaded
}) {
  const realRef = useRef();
  if (realRef.current) {
    console.log("f");
    const f = realRef.current.chartInstance.ctx;
    f.save();
    f.globalCompositeOperation = "destination-over";
    f.fillStyle = "#EEEEEE";
    f.fillRect(
      0,
      0,
      realRef.current.chartInstance.width,
      realRef.current.chartInstance.height
    );
    f.restore();
  }
  const [hideChart, setHiddeChart] = useState(false);

  const countQueryImages = dataForCharts.consultImages;
  const countQueryLaboratories = dataForCharts.consultLaboratories;
  const countQueryQuerys = dataForCharts.totalAppointments;

  const countEventImages = dataForCharts.eventImages;
  const countEventLaboratories = dataForCharts.eventLaboratories;
  const countEventQuerys = dataForCharts.eventConsult;
  useEffect(() => {
    (async () => {
      // when the chart load get us the base64image
      const img =
        realRef.current &&
        (await realRef.current.chartInstance.toBase64Image());
      const f =
        countQueryImages === 0 &&
        countQueryLaboratories === 0 &&
        countQueryQuerys === 0 &&
        countEventImages &&
        countEventLaboratories &&
        countEventQuerys
          ? "NO-DATA"
          : img;

      if (chartsLoaded.coverageTypes !== f) {
        onChangeValue({
          key: "chartsLoaded",
          value: {
            ...chartsLoaded,
            coverageTypes: f
          }
        });
      }
      // this is for hidde the chart
      if (chartsLoaded.coverageTypes) {
        setHiddeChart(true);
      }
    })();
  }, [
    realRef,
    onChangeValue,
    chartsLoaded,
    countQueryImages,
    countQueryLaboratories,
    countQueryQuerys,
    countEventImages,
    countEventLaboratories,
    countEventQuerys
  ]);

  const data = {
    labels: [`Imágenes`, `Laboratorios`, `Consultas`],
    datasets: [
      {
        backgroundColor: "#DE681B",
        data: [countQueryImages, countQueryLaboratories, countQueryQuerys],
        label: `TOTAL CONSULTAS`
      },
      {
        backgroundColor: "#B78D72",
        data: [countEventImages, countEventLaboratories, countEventQuerys],
        label: `TOTAL EVENTOS`
      }
    ]
  };

  const options = {
    legend: {
      // position: 'left',
      align: "start",
      labels: {
        fontSize: 27
        // paddingRight: 80
      }
    },
    // defaultFontSize: 70,
    scales: {
      yAxes: [
        {
          ticks: {
            // callback: function (value) {
            //   return value % 1 === 0 ? value : null;
            // },
            fontSize: 30
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            // callback: function (value) {
            //   return value % 1 === 0 ? value : null;
            // },
            fontSize: 30
          }
        }
      ]
    },
    indexAxis: "x",
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 0,
      onComplete: e => {
        data.datasets.forEach(function(dataset, i) {
          var meta = e.chart.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            if (data > 0) {
              e.chart.ctx.fillText(data, bar._model.x, bar._model.y - 5);
            }
          });
        });
      }
    },

    plugins: {
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart"
      }
    }
  };

  return (
    <div hidden={hideChart}>
      {/* <HorizontalBar height={300} options={options} data={data} ref={realRef} /> */}
    </div>
  );
}
