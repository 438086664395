import ApolloClient from "apollo-client";

import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "@apollo/client/utilities";

//!aqui el link inicio

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_APOLLO_CLIENT_URI}/graphql`
  // createUploadLink({ uri: `${opts.uri}/graphql` })
});

const httpLink2 = createUploadLink({
  uri: `${process.env.REACT_APP_APOLLO_CLIENT_URI}/graphql`
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_APOLLO_CLIENT_URI.replace(
    "http",
    "ws"
  )}/subscriptions`,
  options: {
    reconnect: true
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink2
);
console.log(splitLink);
//!aqui el link fin

const withToken = opts =>
  setContext(async request => {
    const accessToken = await opts.getTokenSilently();
    return {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    };
  });

// function Client(opts) {
//   console.log({ opts });
//   return new ApolloClient({
//     link: ApolloLink.from([
//       withToken(opts),
//       onError(({ graphQLErrors, networkError }) => {
//         if (networkError && networkError.statusCode === 401) {
//           opts.logout({ returnTo: window.location.origin });
//         }
//       }),

//       createUploadLink({ uri: `${opts.uri}/graphql` })
//     ]),

//     cache: new InMemoryCache({ addTypename: false })
//   });
// }

function Client(opts) {
  return new ApolloClient({
    link: ApolloLink.from([
      withToken(opts),
      onError(({ graphQLErrors, networkError }) => {
        if (networkError && networkError.statusCode === 401) {
          opts.logout({ returnTo: window.location.origin });
        }
      }),
      splitLink
    ]),
    cache: new InMemoryCache({ addTypename: false })
  });
}

export default Client;
