import gql from "graphql-tag";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useQuery, useApolloClient } from "react-apollo-hooks";
import { toast } from "react-toastify";

const GET_ARS_SCHEMA40 = gql`
  query getArsForSchema40($where: DataForChartsWhere) {
    getArsForSchema40(where: $where) {
      id
      name
    }
  }
`;

const GET_ANALITIC_DETAILS_FOR_ARS = gql`
  query getAnaliticDetailsForARS($where: getAnaliticDetailsForARSInput) {
    getAnaliticDetailsForARS(where: $where) {
      analitics {
        date
        ncf
        identificationCard
        nss
        firstName
        lastName
        address
        provinces
        birth
        phone
        email
        center
        authorizationNo
        codeSimon
        quantity
        name
        results
        productId
        min
        max
        unit
        ars
        code
      }
      hemograma {
        sales
        date
        ncf
        identificationCard
        nss
        firstName
        lastName
        address
        provinces
        birth
        phone
        email
        center
        authorizationNo
        codeSimon
        quantity
        name
        productId
        ars
        code
        rbc
        hgb
        hct
        mcv
        mch
        mchc
        rdwCv
        rdwSd
        plt
        mpv
        neuPorciento
        lymphPorciento
        mxdPorciento
        neuNumero
        lymphNumero
        mxdNumero
      }
      orina {
        sales
        date
        ncf
        identificationCard
        nss
        firstName
        lastName
        address
        provinces
        birth
        phone
        email
        center
        authorizationNo
        codeSimon
        quantity
        name
        productId
        ars
        code

        color
        aspecto
        olor
        densidad
        ph
        proteinas
        glucosa
        cetonas
        bilirubina
        urubilinogeno
        sangreOculta
        nitrito

        leucocitos
        hematies
        celEpiteliales
        fibrasMucosa
        bacterias
        cilindro
        cristales
        levadura
        celRenales
      }
      coprologico {
        sales
        date
        ncf
        identificationCard
        nss
        firstName
        lastName
        address
        provinces
        birth
        phone
        email
        center
        authorizationNo
        codeSimon
        quantity
        name
        productId
        ars
        code

        consistencia
        olor
        bacterias
        levadura
        leucocitos
        hematies
        grasas
        mucus
        almidones
        sangreOculta
        ascarisLumbricoide
        trichurisTrichura
        uncinaria
        hymenolopisNana
        entorobiusVermicularis
        strongyloideStercolari
        blastocystisHominis
        entamoebaHistolytica
        entamoebaColi
        giardiaLamblia
        endolimaxNana
        lodamoebaBusttchili
        cilomastixMesnili
        amebaHistoliscica
      }

      CultivosData {
        sales
        date
        ncf
        identificationCard
        nss
        firstName
        lastName
        address
        provinces
        birth
        phone
        email
        center
        authorizationNo
        codeSimon
        quantity
        name
        productId
        ars
        code

        antibiotico {
          antibioticId
          antibioticParams
          antibioticResults
          antibioticNumber
          cultivoId
        }
        microscopic {
          epitelios
          levaduras
          bacterias
          leucocitos
          tricomonas
          hematies
        }
      }
      homologations {
        id
        code
        descriptions
      }
      analisisMethods {
        examen
        examId
        analisisId
        unit
        max
        min
        homologationId
        parameter
      }
    }
  }
`;

const useActions = ars => {
  const [state, setState] = useState({
    realLoading: false,
    data: null,
    activeBtnGen: true,
    validate: false,
    chartsLoaded: {
      affiliantesAttendedByRegimen: null,
      affiliatesByControl: [],
      affliatesAttendedByGender: null,
      coverageTypes: null,
      typeQuerys: null
    },
    arsSelected: null,
    selectArsEnabled: false
  });

  const client = useApolloClient();
  const {
    data: { getArsForSchema40 },
    loading: { loading2 }
  } = useQuery(GET_ARS_SCHEMA40, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        billingId: 0
      }
    }
  });

  const { data, loading } = useQuery(
    gql`
      query GetDataForCharts(
        $where: DataForChartsWhere!
        $where2: GetPeriodWhere
      ) {
        data: getDataShema40(where: $where) {
          provider
          ars
          ncf
          period
          rnc
          male
          female
          gays
          total
          contributive
          pensioned
          a0_1
          a2_5
          a6_10
          a11_17
          a18_40
          a41_60
          a61_80
          a81_110
          new
          following
          consultImages
          eventImages
          consultLaboratories
          eventLaboratories
          consultConsult
          eventConsult
          totalAppointments
          totalServices
          programs {
            name
            news
            following
            controledcontributivemale
            controledcontributivefemale
            unControledcontributivemale
            unControledcontributivefemale
          }
        }

        periods: getPeriods(where: $where2) {
          billingId
          date
          ncf
          arsId
        }

        arses: arses {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: true,
      variables: {
        where: {
          billingId: state.periodSelected?.value
        },
        where2: {
          arsId: state.arsSelected?.value || get(ars, "[0].value", null)
        }
      }
    }
  );

  const managementDownload = async () => {
    console.log(state);
    let validate = false;
    if (!state.arsSelected || !state.periodSelected) {
      toast.error("Debe Seleccionar todos los campos");
      setState(state => ({ ...state, validate: true }));
    } else {
      const ncf = state.periodSelected.label.split("-");
      const {
        data: { getAnaliticDetailsForARS }
      } = await client.query({
        query: GET_ANALITIC_DETAILS_FOR_ARS,
        fetchPolicy: "network-only",
        variables: {
          where: {
            arsId: state?.arsSelected?.value,
            ncf: ncf[3].trim() //'B1500000052'
          }
        }
      });

      console.log(getAnaliticDetailsForARS);

      if (
        !getAnaliticDetailsForARS.analitics.length &&
        !getAnaliticDetailsForARS.hemograma.length &&
        !getAnaliticDetailsForARS.coprologico.length &&
        !getAnaliticDetailsForARS.orina.length &&
        !getAnaliticDetailsForARS.CultivosData.length
      ) {
        toast.error("No existen analiticas para este NCF");
        return { validate };
      } else {
        // toast.success('descarga')
        validate = true;

        return { validate, getAnaliticDetailsForARS };
      }
    }
  };

  useEffect(() => {
    if (data && !loading && getArsForSchema40 && !loading2) {
      console.log(data);
      setState(state => ({ ...state, ...data }));

      //ars del usuario
      const obj = getArsForSchema40.map(item => ({
        value: item.id,
        label: item.name
      }));

      if (!isEmpty(ars)) {
        setState(prev => ({
          ...prev,
          arsSelected: ars,
          selectArsEnabled: true
        }));
      }

      setState(state => ({
        ...state,
        arses2: obj
      }));
    }
  }, [data, loading, getArsForSchema40, loading2, ars]);
  console.log({ state });

  const onChangeValue = ({ key, value }) => {
    console.log("value", value);
    if (key === "periodSelected") {
      setState({
        ...state,
        realLoading: true,
        validate: true,
        chartsLoaded: {
          affiliantesAttendedByRegimen: null,
          affiliatesByControl: [],
          affliatesAttendedByGender: null,
          coverageTypes: null,
          typeQuerys: null
        },
        [key]: value
      });
      return;
    }
    setState({ ...state, [key]: value });
  };

  useManageLoading({
    state,
    setState,
    loading
  });

  return {
    state,
    data,
    onChangeValue,
    managementDownload,
    loading: state.realLoading
  };
};
export default useActions;

function useManageLoading({ setState, state }) {
  const {
    chartsLoaded: {
      affiliantesAttendedByRegimen,
      affliatesAttendedByGender,
      coverageTypes,
      typeQuerys,
      affiliatesByControl
    }
  } = state;
  useEffect(() => {
    const time = 3000 + affiliatesByControl.length * 1000;
    if (
      affiliantesAttendedByRegimen &&
      affliatesAttendedByGender &&
      coverageTypes &&
      typeQuerys &&
      affiliatesByControl
    ) {
      setTimeout(() => {
        setState(state => ({ ...state, realLoading: false }));
      }, time);
    }
  }, [
    setState,
    affiliantesAttendedByRegimen,
    affliatesAttendedByGender,
    coverageTypes,
    typeQuerys,
    affiliatesByControl
  ]);
}
