import gql from "graphql-tag";
import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { Button, Card, CardBody, Label } from "reactstrap";
import { useParams } from "react-router";
import ReactNumberFormat from "react-number-format";
import Chart from "./ChartLine.js";
import AverageTimeModalDetails from "./components/ModalDetails/index.js";

const QUERY = gql`
  query getAverageTimeByAppointmentByMedicalStaff(
    $where: WhereAverageAndTicket
  ) {
    getAverageTimeDetails: getAverageTimeByAppointmentTypeDetails(
      where: $where
    ) {
      expectedDuration
      appointmentName
      averageDuration
      appointmentId
      medicalStaffId
      medicalStaffName
      center
      centerId
    }
    getAverageTimeByAppointmentByMedicalStaffByMonth: getAverageTimeByAppointmentByMedicalStaffByMonth(
      where: $where
    ) {
      name
      msId
      pId
      month
      expectedDuration
      averageDuration
    }
  }
`;

export default function Table() {
  const { appointmentTypeId, startDate, endDate } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const { data, loading } = useQuery(QUERY, {
    variables: {
      where: {
        appointmentTypeId: parseInt(appointmentTypeId),
        startDate,
        endDate
      }
    },
    fetchPolicy: "network-only"
  });

  const appointmentTypeName =
    ((data?.getAverageTimeDetails || [])[0] || {})?.appointmentName ||
    "Cargando...";

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="animated fadeIn">
      {isOpen && (
        <AverageTimeModalDetails
          isOpen={isOpen}
          toggle={toggle}
          item={selected}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <Card>
        <CardBody>
          <Label className="font-weight-bold">{appointmentTypeName}</Label>
          <ReactTable
            data={data?.getAverageTimeDetails || []}
            defaultPageSize={10}
            loading={loading}
            loadingText="Cargando..."
            columns={[
              {
                Header: "Médico",
                accessor: "medicalStaffName"
              },
              {
                Header: "Centro",
                accessor: "center"
              },
              {
                Header: "Optimo (Min)",
                className: "text-center",
                Cell: ({ original: { expectedDuration } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    displayType="text"
                    value={expectedDuration}
                  />
                )
              },
              {
                Header: "Promedio (Min)",
                className: "text-center",
                Cell: ({ original: { averageDuration } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    value={averageDuration}
                  />
                )
              },
              {
                Header: "Desviación",
                Cell: ({ original: { averageDuration, expectedDuration } }) => (
                  <div className="text-center">
                    <ReactNumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix="%"
                      decimalScale={2}
                      value={
                        ((averageDuration - expectedDuration) /
                          expectedDuration) *
                        100
                      }
                    />
                  </div>
                )
              },
              {
                Header: "Opciones",
                Cell: ({ original }) => (
                  <Button
                    onClick={() => {
                      toggle();
                      setSelected(original);
                    }}
                    color="success"
                  >
                    Ver detalle
                  </Button>
                )
              }
            ]}
          />
          <div>
            <Chart state={data} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
