import gql from "graphql-tag";

export const GET_AVERAGE_TICKET_DETAILS_FOR_STAFF = gql`
  query getAverageTicketDetailsForStaff($where: WhereAverageAndTicket) {
    getAverageTicketDetailsForStaff(where: $where) {
      productTypeId
      productType
      salesInAppointment
      expectedTicket
      generate
      noGenerate
    }
  }
`;
