import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import moment from "moment";
import ReactSelect from "react-select";
import ReactTable from "react-table-v6";
import { useActions } from "./useActions";
import { onChangeState } from "../../../../../../../../../utils/hooks";
import ReactNumberFormat from "react-number-format";
import { sumFooterUtils } from "../../../../../../../../../../utils/utils";

export const ModalDetailsNoGenerate = ({ isOpen, item, onChange }) => {
  const { state, actions } = useActions({ item });
  const toggle = () => onChange("isOpen", false);

  return (
    <div className="fadeIn animated">
      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <Label>Detalle de Valor No Facturado</Label>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="3">
              <FormGroup>
                <Label>Médico</Label>
                <Input disabled value={item.item.medicalStaffName || ""} />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label>Tipo de Cita</Label>
                <Input disabled value={item.appointmentName || ""} />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label>Período</Label>
                <Input
                  disabled
                  className="text-center"
                  value={`${moment(item.startDate).format(
                    "DD/MM/YYYY"
                  )} - ${moment(item.endDate).format("DD/MM/YYYY")}`}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <FormGroup>
                <Label>Tipo de Producto</Label>
                <ReactSelect
                  value={state.productTypeSelected}
                  options={item.productTypes || []}
                  getOptionLabel={item => item.productType}
                  getOptionValue={item => item.productTypeId}
                  onChange={selected =>
                    onChangeState(
                      "productTypeSelected",
                      selected,
                      actions.setState
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <ReactTable
                data={state.data || []}
                defaultPageSize={10}
                columns={[
                  {
                    Header: "Producto",
                    accessor: "product",
                    Footer: () => <div className="text-center">Total</div>
                  },
                  {
                    Header: "Valor No Facturado",
                    columns: [
                      {
                        Header: "Cantidad",
                        accessor: "quantity",
                        className: "text-center",
                        Footer: ({ data }) => (
                          <span>{sumFooterUtils(data, "quantity")}</span>
                        )
                      },
                      {
                        Header: "Valor",
                        accessor: "amount",
                        Cell: ({ original: { amount } }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={amount}
                            thousandSeparator={true}
                          />
                        ),
                        Footer: ({ data }) => (
                          <ReactNumberFormat
                            renderText={value => (
                              <div className="text-center"> {value} </div>
                            )}
                            prefix="$"
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType="text"
                            value={sumFooterUtils(data, "amount")}
                            thousandSeparator={true}
                          />
                        )
                      }
                    ]
                  },
                  {
                    Header: "Proporción",
                    Cell: ({ original: { amount, copago } }) => (
                      <ReactNumberFormat
                        renderText={value => (
                          <div className="text-center"> {value} </div>
                        )}
                        suffix="%"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                        value={
                          (amount / sumFooterUtils(state.data, "amount")) * 100
                        }
                        thousandSeparator={true}
                      />
                    ),
                    // Footer: ({ data }) => <span>{(sumFooterUtils(data, 'amount') / sumFooterUtils(data, 'copago')) * 100}</span>
                    Footer: ({ data }) => (
                      <ReactNumberFormat
                        renderText={value => (
                          <div className="text-center"> {value} </div>
                        )}
                        suffix="%"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                        value={
                          (sumFooterUtils(data, "amount") /
                            sumFooterUtils(data, "amount")) *
                          100
                        }
                        thousandSeparator={true}
                      />
                    )
                  }
                ]}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="float-left">
          <Button color="success" onClick={() => toggle()}>
            {"Cerrar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
