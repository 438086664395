import { useState, useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import {} from "./queries";
import { onChangeState } from "../../../../../../../../../utils/hooks";
import { GET_NO_GENERATE_DETAILS } from "./queries";

const initialState = {
  data: [],
  productTypeSelected: {},
  loading: true
};

export const useActions = ({ item }) => {
  const [state, setState] = useState(initialState);

  const { data, loading } = useQuery(GET_NO_GENERATE_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        appointmentTypeId: item.item.appointmentId,
        staffId: item.item.medicalStaffId,
        productTypeId:
          state?.productTypeSelected?.productTypeId ||
          item?.original?.productTypeId,
        startDate: item.startDate,
        endDate: item.endDate
      }
    }
  });

  useEffect(() => {
    if (data && !loading) {
      if (!state.productTypeSelected.productTypeId) {
        const { productTypeId, productType } = item.original;
        onChangeState(
          "productTypeSelected",
          { productTypeId, productType },
          setState
        );
      }
      setState(prev => ({
        ...prev,
        data: data?.getNoGenerateDetails || [],
        loading
      }));
    }
  }, [data, loading, item, state.productTypeSelected]);

  return {
    state,
    actions: {
      setState
    }
  };
};
