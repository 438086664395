import get from "lodash/get";
import filter from "lodash/filter";
import reduce from "lodash/reduce";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { toast } from "react-toastify";
import { currencyFormat } from "../../utils/utils";
export const distinct = (path, arr) => {
  const getValue = (obj, path) => {
    const extractedKeys = [];
    let memo = "";
    const arr = Array.from(path);
    arr.forEach((x, index) => {
      if (index === arr.length - 1) {
        memo += x;
        extractedKeys.push(memo);
      } else if (x === ".") {
        extractedKeys.push(memo);
        memo = "";
      } else {
        memo += x;
      }
    });

    return extractedKeys.reduce((acc, curr) => (acc ? acc[curr] : null), obj);
  };

  function distinctCore() {
    const uniqueIds = arr.reduce((acc, curr) => {
      const val = getValue(curr, path);
      if (!acc.includes(val)) {
        const arr = [...acc, val];
        return arr;
      } else {
        return acc;
      }
    }, []);
    return uniqueIds.map(x => arr.find(y => getValue(y, path) === x));
  }

  return distinctCore();
};

export function filterData(items, collection, key) {
  const selecteds = reduce(
    items,
    (obj, item) => ({ ...obj, [get(item, key)]: true }),
    {}
  );
  return filter(collection, item => !selecteds[item.id]);
}

export function nextPriority(values) {
  const ordered = orderBy(values, ["priority"], ["asc"]);
  if (ordered.length > 0) {
    return ordered[ordered.length - 1].priority + 1;
  }
  return 1;
}

export const customParseFloat = val => parseFloat(parseFloat(val).toFixed(2));

export const roundTwoDecimals = num => Math.round(num * 100) / 100;

export const upperCaseAll = string =>
  Array.from(string)
    .map(x => x.toUpperCase())
    .join("");

export const dynamicValuesInArray = ({ array, values, condition }) => [
  ...array,
  ...(condition ? values : [])
];

export function deleteDuplicateArray(arr, prop) {
  var nuevoArray = [];
  var lookup = {};

  for (var i in arr) {
    lookup[arr[i][prop]] = arr[i];
  }

  for (i in lookup) {
    nuevoArray.push(lookup[i]);
  }

  return nuevoArray;
}

export const moneyFormat = value =>
  currencyFormat({ currencySymbol: "" })(value);

export const getValue = (obj, path) => {
  const extractedKeys = [];
  let memo = "";
  const arr = Array.from(path);
  arr.forEach((x, index) => {
    if (index === arr.length - 1) {
      memo += x;
      extractedKeys.push(memo);
    } else if (x === ".") {
      extractedKeys.push(memo);
      memo = "";
    } else {
      memo += x;
    }
  });
  return extractedKeys.reduce((acc, curr) => acc[curr], obj);
};

export const reduceSum = (array, fields) => {
  let initialObj = {};

  if (typeof fields == "string") {
    fields = [fields];
  }

  fields.forEach(x => {
    initialObj[x] = 0;
  });

  return array.reduce((acc, curr) => {
    const toReturn = {};
    fields.forEach(path => {
      toReturn[path] = acc[path] + getValue(curr, path);
    });
    return toReturn;
  }, initialObj);
};

export const formatDate = date => moment(date).format("DD-MM-YYYY");

export const sort = (arr, config) => {
  function getValue(obj, path) {
    const extractedKeys = [];
    let memo = "";
    const arr = Array.from(path);
    arr.forEach((x, index) => {
      if (index === arr.length - 1) {
        memo += x;
        extractedKeys.push(memo);
      } else if (x === ".") {
        extractedKeys.push(memo);
        memo = "";
      } else {
        memo += x;
      }
    });
    return extractedKeys.reduce((acc, curr) => acc[curr], obj);
  }
  const hasPath = config && "path" in config;

  const res = arr.sort((prev, curr) => {
    const currFinded = hasPath ? getValue(curr, config.path) : curr;
    const prevFinded = hasPath ? getValue(prev, config.path) : prev;
    if (currFinded > prevFinded) {
      return -1;
    } else if (currFinded < prevFinded) {
      return 1;
    } else {
      return 0;
    }
  });
  return res;
};

export const isDateInRange = ({
  date,
  time,
  after = true,
  inMonth = false
}) => {
  const today = moment();
  const eachTime = inMonth ? "months" : "days";
  const afterDate = moment().add(time, eachTime);
  const beforeDate = moment().subtract(time, eachTime);
  if (after) {
    return moment(date).isBetween(today, afterDate, "days", true);
  } else {
    return moment(date).isBetween(beforeDate, today, "days", true);
  }
};

//vuelve mayuscula la primera letra del la codena de texto
export const firstLetterUpperCase = data => {
  let text;
  text = data.charAt(0).toUpperCase() + data.slice(1);
  return text;
};

export const fullnameFirstLetterUpperCase = data => {
  let arrayNameUser = data.split(" ");

  let fullnameChange = "";

  for (let i = 0; i < arrayNameUser.length; i++) {
    let modifiedText = arrayNameUser[i].toLowerCase();
    let modifiedText2 =
      modifiedText.charAt(0).toUpperCase() + modifiedText.slice(1);

    fullnameChange = fullnameChange + " " + modifiedText2;
  }

  return fullnameChange;
};

export const formatMoney = data => {
  const options2 = { style: "currency", currency: "USD" };
  const addComaNumbre = new Intl.NumberFormat("en-US", options2);

  return addComaNumbre.format(data);
};

export const withGrapqlErrorHandler = (fn, callback) => async () => {
  try {
    await fn();
  } catch (error) {
    const message = error.message.replace("GraphQL error: ", "");
    toast.error(message);
    callback && (await callback());
  }
};

//funcion para el cambio de color de los taps

export const colorChange = (activeTap, id, color1, color2, validate, size) => {
  let style = {};

  if (validate) {
    style = { backgroundColor: color2 };
  } else {
    style = { color: activeTap !== id ? color1 : color2, fontSize: size };
  }

  return style;
};

export const initCap = data => {
  let arrayNameUser = data?.split(" ");
  let fullnameChange = "";

  for (let i = 0; i < arrayNameUser?.length; i++) {
    let modifiedText = arrayNameUser[i].toLowerCase();
    let modifiedText2 =
      modifiedText.charAt(0).toUpperCase() + modifiedText.slice(1);

    fullnameChange = fullnameChange + " " + modifiedText2;
  }

  return fullnameChange;
};
