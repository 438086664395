import React from "react";

const resultValue = item => {
  switch (item.typeId) {
    case 1:
      return item.results === "true" ? "Positivo" : "Negativo";
    case 2:
      return item.results === "true" ? "Si" : "No";
    default:
      return item.results;
  }
};

export const CustomResults = item => {
  return <div>{resultValue(item)}</div>;
};
