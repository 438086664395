import React from "react";
import List from "./pages/list";
import AnalysisMethodsManagement from "./pages/management/index";

const routes = [
  {
    exact: true,
    path: "/administration/analysis-methods",
    name: "Laboratorio - Métodos de Análisis",
    component: List
  },
  {
    exact: true,
    path: "/administration/analysis-methods/new",
    name: "Captura de Valores de Referencia",
    component: AnalysisMethodsManagement
  },
  {
    exact: true,
    path: "/administration/analysis-methods/edit/:id",
    name: "Editando Valores de Referencia",
    component: AnalysisMethodsManagement
  }
];

export default routes;
