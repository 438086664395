import React from "react";
import { Bar } from "react-chartjs-2";
export default function Chart({ state }) {
  const typeAppointmentNames = state.averageTicket.map(e => e.appointmentName);
  const averageValues = state.averageTicket.map(e => e.averageValue);
  const expectedValues = state.averageTicket.map(e => e.expectedValue);

  const src = {
    data: {
      labels: typeAppointmentNames,
      datasets: [
        {
          label: "Optimo",
          data: expectedValues,
          backgroundColor: "#1A73D2"
        },
        {
          label: "Promedio",
          data: averageValues,
          fill: false,
          backgroundColor: "#DF681B"
        }
      ]
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: value => {
                return value % 1 === 0 ? `$${Math.floor(value)}` : null;
              },
              min: 0
            }
          }
        ]
      }
    }
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar data={src.data} options={src.options} />
    </div>
  );
}
