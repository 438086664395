import { gql } from "@apollo/client";

export const GET_ANALYSIS_METHOD = gql`
  query GetAnalysisMethod($awhere: AnalysisMethodWhereUniqueInput!) {
    methods: getAnalysisMethod(where: $awhere) {
      id
      name
      products {
        id
        name
      }
      analysisMethodProducts {
        id
        unitMeasurement
        typeId
        enabled
        type {
          id
          name
        }
        minimum
        maximum
        interpretation
        endAge
        startAge
        gender {
          id
          name
        }
        product {
          id
          name
          duration
          cost
        }
      }
    }
    genders: genders {
      id
      name
    }
    products: products(where: { productTypeId: 1 }) {
      id
      name
    }
    types: getAllAnalysisMethodTypes {
      id
      name
    }
  }
`;

export const CREATE_ANALYSIS_METHOD = gql`
  mutation createAnalysisMethod($data: AnalysisMethodCreateInput!) {
    createAnalysisMethod(data: $data)
  }
`;

export const UPDATE_ANALYSIS_METHOD = gql`
  mutation updateAnalysisMethod($data: AnalysisMethodUpdateInput!) {
    updateAnalysisMethod(data: $data)
  }
`;

export const TOGGLE_ENABLED_ANALYSIS_METHOD_PRODUCT = gql`
  mutation toggleEnabledProduct(
    $data: ToggleEnabledDataInput!
    $where: ToggleEnabledWhereInput!
  ) {
    toggleEnabledProduct(data: $data, where: $where)
  }
`;

export const UPDATE_ANALYSIS_METHOD_PRODUCT = gql`
  mutation updateAnalysisMethod($data: AnalysisMethodProductUpdateInput!) {
    updateAnalysisMethodProduct(data: $data)
  }
`;
